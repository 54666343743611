<template>
  <v-client-table
    :data="data"
    :options="options"
    :columns="columns"
    :theme="theme"
  >
    <span
      slot="reportDate"
      slot-scope="props"
    >
      <span v-if="period === 'week'">
        {{ formatWeek(props.row.date) }}
      </span>
      <span v-else-if="period === 'month'">
        {{ formatMonth(props.row.date) }}
      </span>
      <span v-else>
        {{ formatDate(props.row.date) }}
      </span>
    </span>
    <span
      slot="difference"
      slot-scope="props"
    >
      <span
        v-if="(props.row.base_tariff - props.row.fact_tariff) > 0"
        class="text-success"
      >{{ numberRUFormat(Math.round(props.row.base_tariff - props.row.fact_tariff)) }} руб.</span>
      <span
        v-else-if="(props.row.base_tariff - props.row.fact_tariff) < 0"
        class="text-danger"
      >{{ numberRUFormat(Math.round(props.row.base_tariff - props.row.fact_tariff)) }} руб.</span>
      <span v-else>0 руб.</span>
    </span>
    <tr
      slot="appendBody"
    >
      <td />
      <td>
        <span
          v-if="(total.base_tariff - total.fact_tariff) > 0"
          class="text-success"
        >{{ numberRUFormat(Math.round(total.base_tariff - total.fact_tariff)) }} руб.</span>
        <span
          v-else-if="(total.base_tariff - total.fact_tariff) < 0"
          class="text-danger"
        >{{ numberRUFormat(Math.round(total.base_tariff - total.fact_tariff)) }} руб.</span>
        <span v-else>0 руб.</span>
      </td>
    </tr>
  </v-client-table>
</template>

<script>
import moment from 'moment';
import {numberToRUFormat} from '@/components/mixins/helpers';

export default {
  name: 'RouteTariffDifferenceSummaryTable',
  props: {
    data: {
      type: Array,
      default: function() {
        return [];
      },
    },
    total: {
      type: Object,
      default: function() {
        return {};
      },
    },
    period: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      columns: [
        'reportDate',
        'difference',
      ],
      options: {
        headings: {
          'reportDate': 'Период',
          'base_tariff': 'Базовый тариф',
          'fact_tariff': 'Фактический тариф',
          'difference': 'Отклонения от базового тарифа',
        },
        sortable: ['reportDate', 'difference'],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        perPage: 10,
        perPageValues: [10, 25, 50],
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      theme: 'bootstrap4',
    };
  },
  methods: {
    formatDate(date) {
      return date ? moment(date).format('DD.MM.YYYY') : '';
    },
    formatWeek(date) {
      return date ? moment(date).format('WW Неделя YYYY') : '';
    },
    formatMonth(date) {
      return date ? moment(date).format('MM.YYYY') : '';
    },
    numberRUFormat(value) {
      return numberToRUFormat(value);
    },
  },
};
</script>

<style scoped>

</style>
