<template>
  <b-card-body :class="{'loading': loading}">
    <route-tariff-difference-main-table
      :data="data"
      :total="total"
      :period="filter.period"
    />
    <route-tariff-difference-summary-table
      :data="dataByDate"
      :total="total"
      :period="filter.period"
    />
    <b-row>
      <b-col
        lg="12"
      >
        <route-tariff-difference-chart
          :data="dataByDate"
          :period="filter.period"
        />
      </b-col>
    </b-row>
  </b-card-body>
</template>

<script>
import {routesTariffDifferenceStatistic} from '@/services/api';
import RouteTariffDifferenceMainTable
  from './RouteTariffDifferenceMainTable';
import RouteTariffDifferenceChart from './RouteTariffDifferenceChart';
import RouteTariffDifferenceSummaryTable
  from '@/views/Reports/ReportBlocks/RouteTariffDifference/RouteTariffDifferenceSummaryTable';
import moment from 'moment';
import axios from 'axios';

export default {
  name: 'RouteTariffDifferenceData',
  components: {
    RouteTariffDifferenceSummaryTable,
    RouteTariffDifferenceChart,
    RouteTariffDifferenceMainTable},
  props: {
    filter: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      data: [],
      dataByDate: [],
      total: {},
      loading: true,
      cancelTokenSourse: null,
    };
  },
  watch: {
    filter: {
      handler: function(newVal) {
        this.routesTariffDifferenceStatistic(newVal);
      },
      deep: true,
    },
  },
  mounted() {
    this.routesTariffDifferenceStatistic(this.filter);
  },
  methods: {
    formatDate(date) {
      return date ? moment(date).format('DD.MM.YYYY') : '';
    },
    formatWeek(date) {
      return date ? moment(date).format('WW-YYYY') : '';
    },
    formatMonth(date) {
      return date ? moment(date).format('MM.YYYY') : '';
    },
    dateByPeriod(dateString) {
      if (this.filter.period === 'week') {
        return this.formatWeek(dateString);
      } else if (this.filter.period === 'month') {
        return this.formatMonth(dateString);
      } else {
        return this.formatDate(dateString);
      }
    },
    async routesTariffDifferenceStatistic(data) {
      if (this.cancelTokenSourse) {
        await this.cancelTokenSourse.cancel();
      }
      const CancelToken = axios.CancelToken;
      this.cancelTokenSourse = CancelToken.source();
      this.loading = true;
      const response = await routesTariffDifferenceStatistic(
        this.$store.state.user.ownerId,
        data,
        this.cancelTokenSourse?.token,
      );
      if (response && response.status === 200) {
        this.data = response.data.items;
        this.total = response.data.total;
        this.dataByDate = [];
        const dataByDate = {};
        this.data.forEach((route)=>{
          if (Number(route.base_tariff)!==0 && Number(route.fact_tariff) !==0 ) {
            if (!!dataByDate[this.dateByPeriod(route.date)]) {
              dataByDate[this.dateByPeriod(route.date)].base_tariff += Number(route.base_tariff);
              dataByDate[this.dateByPeriod(route.date)].fact_tariff += Number(route.fact_tariff);
              dataByDate[this.dateByPeriod(route.date)].difference += route.fact_tariff - route.base_tariff;
            } else {
              dataByDate[this.dateByPeriod(route.date)] = {
                date: route.date,
                base_tariff: Number(route.base_tariff),
                fact_tariff: Number(route.fact_tariff),
                difference: route.fact_tariff - route.base_tariff,
              };
            }
          }
        });
        this.dataByDate =Object.values(dataByDate);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>

</style>
