<template>
  <div
    v-if="data.length>0"
    class="g-chart-container"
  >
    <h4 class="g-chart-container__title">
      Динамика Отклонения фактических тарифов от базовых
    </h4>
    <GChart
      type="ColumnChart"
      :settings="settings"
      :data="chartData"
      :options="options"
    />
  </div>
</template>

<script>
export default {
  name: 'RouteTariffDifferenceChart',
  props: {
    data: {
      type: Array,
      default: function() {
        return [];
      },
    },
    period: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      settings: {language: 'ru', packages: ['corechart', 'line']},
      baseOptions: {
        explorer: {
          axis: 'horizontal',
          keepInBounds: true,
          maxZoomIn: 0.1,
        },
        chartArea: {
          top: '10%',
          left: '5%',
          height: '80%',
          width: '90%',
        },
        height: 800,
        width: '100%',
        titlePosition: 'none',
        annotations: {
          textStyle: {color: 'black', fontSize: 14, bold: true},
        },
        textStyle: {color: 'black', fontSize: 12},
        // colors: ['#b19ded', '#803054', '#2e77bf', '#cfd13f'],
        colors: ['blue', 'red', 'green'],
        legend: {
          position: 'bottom',
          maxLines: 2,
          textStyle: {color: 'black', fontSize: 12},
        },
        hAxis: {
          allowContainerBoundaryTextCutoff: true,
          minTextSpacing: 20,
          maxTextLines: 2,
          slantedText: true,
          showTextEvery: 1,
          textStyle: {
            fontSize: 12,
            color: 'black',
            bold: true,
            italic: false,
          },

        },
      },
    };
  },
  computed: {
    chartData() {
      const result = [
        ['',
          'Экономия',
          {role: 'annotation', sourceColumn: 1},
          'Перерасход',
          {role: 'annotation', sourceColumn: 2},
        ],
      ];
      this.data.forEach((item) => {
        const profit =item.base_tariff - item.fact_tariff;
        result.push([
          new Date(item.date),
          profit > 0 ? profit : 0,
          profit > 0 ? profit : null,
          profit < 0 ? profit : 0,
          profit < 0 ? profit : null,
        ]);
      });
      return result;
    },
    options() {
      const result = this.baseOptions;
      const ticks = [];
      this.data.forEach((item) => {
        ticks.push(
          new Date(item.date),
        );
      });
      result.hAxis.ticks = ticks;
      if (this.period ==='month') {
        result.hAxis.format = 'MM-YYYY';
      } else if (this.period ==='week') {
        result.hAxis.format = 'w Неделя YY г.';
      } else {
        result.hAxis.format = 'dd.MM.YYYY';
      }
      return result;
    },
  },
};
</script>
<style scoped  lang="scss">
.g-chart-container {
  position: relative;

  &__title {
    position: absolute;
    z-index: 1;
    top: 12px;
    left: 10%;
    width: 80%;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
  }
  &__form-group {
    position: absolute;
    z-index: 1;
    top: 68px;
    left: 10%;
    width: 80%;
  }
}
</style>
